import { Form, Input, message, Modal, Select, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { AvailableSemester, EditUserModalProps, Enrollment } from "../models/models";
import StudentSkillList from "./StudentSkillList";

const ChangeProfessorSkills: React.FC<EditUserModalProps> = ({
    open,
    onSaved,
    onCancel,
    user,
    disabled
}) => {
    const [form] = Form.useForm();
    const [enrollments, setEnrollments] = useState<Array<Enrollment>>([]);
    const [allCourses, setAllCourses] = useState<Array<AvailableSemester>>([]);
    const [ready1, setReady1] = useState(false);
    const context = useContext(AppContext);
    
    useEffect(() => {
        if (!open) {
            setReady1(false);
            form.resetFields();
            return;
        }

        setReady1(true);
    }, [open]);

    return (
        <Modal open={open}
            width={"60%"}
            title="Δεξιότητες φοιτητή"
            okText="OK"
            cancelText="Ακύρωση"
            onCancel={() => {
                onCancel();
            }}
            onOk={() => {
                onSaved(null);
            }}
        >
            {!ready1 && <Spin size="small" />}
            {ready1 &&
                <StudentSkillList userId={user?.id} ready={ready1} setReady={setReady1}></StudentSkillList>
            }
        </Modal>
    );
};

export default ChangeProfessorSkills;