import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Checkbox, Input, message, Modal, Pagination, Space, Table, TableColumnsType } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { UserInfo } from "../models/models";
import EditUserModal from "./EditUserModal";
import EnrollUserModal from "./EnrollUserModal";
import ChangeProfessorSkills from "./ChangeProfessorSkills";

const UsersTable: React.FC<{ users: Array<UserInfo>, setReady: React.Dispatch<React.SetStateAction<boolean>> }> = ({ users, setReady, ...props }) => {
    const [enrollStudentChange, setEnrollStudentChange] = useState<{ id: string, name: string } | null>(null);
    const [editUserChange, setEditUserChange] = useState<UserInfo | null>(null);
    const [userSkills, setUserSkills] = useState<UserInfo | null>(null);
    const [usersFilter, setUserFilter] = useState("");

    const columns: TableColumnsType<UserInfo> = [
        {
            title: "Ρόλος", filterSearch: true,
            filters: [
                { text: 'Student', value: 'student', },
                { text: 'Professor', value: 'professor', },
                { text: 'Admin', value: 'admin', },
            ],
            onFilter: (value: string | number | boolean, record: UserInfo) => record.roles.find(r => r.toLowerCase().includes((value as string))) != null,
            sorter: (a, b) => a.roles[0].localeCompare(b.roles[0]), width: 100, ellipsis: true, render: (value, record, index) => { return <Badge status={value.admin ? "success" : value.professor ? "warning" : "processing"} text={value.roles[0]}></Badge> }
        },
        {
            title: 'Email', filterSearch: true, onFilter: (value: string | number | boolean, record: UserInfo) => record.email.toLowerCase().includes((value as string)),
            sorter: (a, b) => a.email.localeCompare(b.email), ellipsis: true, dataIndex: "email"
        },
        { title: 'Επιβ/μένο', sorter: (a, b) => (a.emailConfirmed === b.emailConfirmed) ? 0 : a.emailConfirmed ? -1 : 1, ellipsis: true, width: 100, render: (value: UserInfo, record, index) => { return <Checkbox defaultChecked={value.emailConfirmed} disabled /> } },
        { title: 'Όνομα', sorter: (a, b) => a.lastName.localeCompare(b.lastName), ellipsis: true, render: (value, record, index) => { return value.firstName + " " + value.lastName } },
        { title: 'Ημ/νια Εγγραφής', sorter: (a, b) => moment(a.registrationDate).diff(moment(b.registrationDate)), ellipsis: true, render: (value, record, index) => { return !value.registrationDate ? "" : moment(value.registrationDate).format("ddd, DD MMM YYYY") } },
        {
            title: 'Πληροφορίες', ellipsis: true,
            sorter: (a, b) => a.studentNumber?.localeCompare(b?.studentNumber) ?? 0,
            render: (value, record, index) => {
                if (value.admin) {
                    return <></>;
                }

                return <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li><Badge status={"success"} text={`Μαθήματα ${value.enrolledCoursesCount}`}></Badge></li>
                    {value.student && <li><Badge status="processing" text={`Αριθμός μητρώου ${value.studentNumber}`}></Badge></li>}
                </ul>;
            }
        },
        {
            title: '', fixed: "left", width: 450, render: (value, record, index) => {
                return <Space wrap size={0} direction="horizontal">
                    <Button type="primary" onClick={() => { setEditUserChange(value) }}>Επεξεργασία</Button>
                    <Button type="primary" onClick={() => { setEnrollStudentChange({ id: value.id, name: value.firstName + " " + value.lastName + " (" + value.email + ")" }); }}>Εγγραφές</Button>
                    <Button type="primary" onClick={() => { setUserSkills(value) }}>Δεξιότητες</Button>
                    <Button type="primary" danger onClick={() => { deleteUser(value); }}><DeleteOutlined /></Button>
                </Space>
            }
        }
    ];

    const deleteUserOk = async (user: UserInfo) => {
        const response = await fetch(`/api/User/Delete/${user.id}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
        });

        if (response.status !== 200) {
            message.error("Συνέβει ένα σφάλμα κατα την διαγραφή του λογαριασμού");
            return;
        }

        setReady(false);
    }

    const deleteUser = (user: UserInfo) => {
        Modal.confirm({
            title: 'Διαγραφή λογαριασμού',
            icon: <ExclamationCircleOutlined />,
            content: `Είστε σίγουρος οτι θέλετε να διαγράψετε το χρήστη ${user.firstName} ${user.lastName}`,
            okText: 'Ναι',
            cancelText: 'Όχι',
            onOk() {
                deleteUserOk(user);
            },
            onCancel() {
            },
        });
    }

    React.useEffect(() => {

    }, [usersFilter]);

    return <>
        <Table size="small" rowKey="id" columns={columns} dataSource={users.filter(u => u.lastName.toLowerCase().includes(usersFilter) || u.firstName.toLowerCase().includes(usersFilter) || u.email.toLowerCase().includes(usersFilter))}
            pagination={{ position: ["topRight"], responsive: true, defaultPageSize: 20, total: users.length, showSizeChanger: true, showQuickJumper: true, showTotal: (total) => <Space direction="horizontal" size={5}><Input.Search value={usersFilter} onChange={(e) => { setUserFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton style={{}} /> <span>{total} Χρήστες</span></Space> }}></Table>
        <EnrollUserModal disabled={false} onCancel={() => { setEnrollStudentChange(null); }} onCreate={() => { setEnrollStudentChange(null); setReady(false); }} open={enrollStudentChange != null} userId={enrollStudentChange?.id ?? ""} userRealName={enrollStudentChange?.name} ></EnrollUserModal>
        <EditUserModal disabled={false} onCancel={() => { setEditUserChange(null); }} onSaved={() => { setEditUserChange(null); setReady(false); }} open={editUserChange != null} user={editUserChange}></EditUserModal>
        <ChangeProfessorSkills disabled={false} onCancel={() => { setUserSkills(null); }} onSaved={() => { setUserSkills(null); setReady(false); }} open={userSkills != null} user={userSkills}></ChangeProfessorSkills>
    </>
}



export default UsersTable;